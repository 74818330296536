"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.templates = void 0;
exports.templates = [
    "code-flow",
    "process-flow",
    "flowchart",
    "decision-flow",
    "pert-light",
    "default",
    "org-chart",
    "knowledge-graph",
    "mindmap",
    "mindmap-dark",
    "network-diagram-dark",
    "network-diagram-icons",
    "playful-mindmap",
];
